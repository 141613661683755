
.edit-profile {
  background: #ffffff;
  box-shadow: 0px 2px 34px rgba(46, 48, 92, 0.11);
  padding: 40px 32px;
  .header {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;

    color: #2e305c;
  }
  .body {
    padding: 10px;
    img {
      display: block;
      margin: 10px auto 35px;
      width: 100px;
      border: #ccc solid thin;
      border-radius: 200px;
      outline-offset: 8px;
    }
    .file-input-tag {
      display: block;
      text-align: center;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      margin-top: 16px;
      margin-bottom: 50px;
      color: #2e305c;
    }
    .custom-input:not(:nth-child(5)) {
      margin-bottom: 16px;
    }
    .submit-btn {
      display: block;
      text-align: center;
      cursor: pointer;
      margin-top: 32px;
      background: #fa8c28;
      border-radius: 4px;
      padding: 23px 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      width: 100%;

      color: #ffffff;
      &.disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 40px 24px;
    .body {
      padding: 37px 0 0;
    }
  }
}
